import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {
    Box,
    Button,
    VStack,
    useColorMode,
    IconButton,
    Flex,
    Heading,
    Container,
    Text,
    Link,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { FaSun, FaMoon } from 'react-icons/fa';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

function HomePage() {
    const { colorMode, toggleColorMode } = useColorMode();
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname , title: "Accueil" });
    }, [location]);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Calendrio</title>
                <link rel="canonical" href="https://calendrio.fr/" />
            </Helmet>
        <Container maxW="container.md" p={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading as="h1" size="2xl" color={colorMode === 'light' ? 'black' : 'teal.200'}>Bienvenue sur Calendrio </Heading>
            </Flex>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
                <VStack spacing={4} align="stretch">
                    <Heading fontSize="xl" color={colorMode === 'light' ? 'black' : 'teal.200'}>Qu'est-ce que notre application fait ?</Heading>
                    <Text mt={4}>Notre application modifie le calendrier des utilisateurs pour créer un lien qui ne contient que les événements qui les concernent. Cela permet de gagner du temps et de se concentrer sur ce qui est vraiment important.</Text>
                    <Button as={RouterLink} to="/create" colorScheme="teal" size="lg">Commencer</Button>

                </VStack>
            </Box>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'} mt={5}>
                <Heading fontSize="xl" color={colorMode === 'light' ? 'black' : 'teal.200'} mb={4}>Aide</Heading>
                <Accordion allowToggle>
                    <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Trouver le lien de l'emploi du temps en fonction de ton groupe
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <Text>Groupe A :</Text>
                            <Text>Lien : <a href="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/6YPaXQnv.shu">https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/5YGyv2WJ.shu</a></Text>

                            <Text>Groupe TP B :</Text>
                            <Text>Lien : <a href="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/pn89glW8.shu">https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/5YrON5nO.shu</a></Text>

                            <Text>Groupe TP C :</Text>
                            <Text>Lien : <a href="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/MYzNaJYZ.shu">https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/x3dpPk3e.shu</a></Text>

                            <Text>Groupe TP D :</Text>
                            <Text>Lien : <a href="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/bWKaVQYa.shu">https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/53yZ0eYj.shu</a></Text>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Ajouter un calendrier via abonnement
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            {/* Ajoutez ici les instructions pour "Ajouter un calendrier sous iOS" */}
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Se servir de l'application
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            {/* Ajoutez ici les instructions pour "Se servir de l'application" */}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </Container>
        </div>
    );
}

export default HomePage;
