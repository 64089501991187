import React, { useState,useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    List,
    VStack,
    useToast,
    useColorMode,
    IconButton,
    Flex,
    Heading,
    Container,
    Badge, FormHelperText,
    ListItem,
    Select
} from '@chakra-ui/react';
import {FaSun, FaMoon, FaCopy} from 'react-icons/fa';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";



function CreateCalendarPage() {
    const [icalUrl, setIcalUrl] = useState('');
    const [unwantedEvents, setunwantedEvents] = useState([]);
    const [generatedCalendarLink, setGeneratedCalendarLink] = useState('');
    const toast = useToast();
    const { colorMode, toggleColorMode } = useColorMode();
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname , title: "Créer un calendrier" });
    }, [location]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        ReactGA.event({
            category: 'Interaction utilisateur',
            action: 'Création d\'un calendrier'
        });
        try {
            if(icalUrl === '') throw new Error('L\'url du calendrier est obligatoire');
            if(unwantedEvents.length === 0) throw new Error('Au moins un évènement non voulu est obligatoire');
            // Simuler un appel API
            const response = await fetch('https://calendrio.fr/generate-calendar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ icalUrl, unwantedEvents }),
            });

            if (!response.ok) throw new Error('Erreur lors de la création du calendrier');

            const result = await response.json();
            setGeneratedCalendarLink(result.url); // Mise à jour de l'état avec le lien généré
            toast({
                title: 'Calendrier créé avec succès.',
                description: result.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // Reset les champs après soumission
            setIcalUrl('');
            setunwantedEvents([]);
        } catch (error) {
            toast({
                title: 'Erreur',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleAddUnwantedItem = (event) => {
        if (event.key === 'Enter' && event.target.value !== '') {
            setunwantedEvents([...unwantedEvents, event.target.value]);
            event.target.value = ''; // Reset l'input
            event.target.focus(); // Focus back on the input
        }
    };

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Calendrio - Créer un calendrier</title>
                <link rel="canonical" href="https://calendrio.fr/create" />
            </Helmet>
        <Container maxW="container.md" p={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading as="h2" size="xl">Créer un nouveau calendrier</Heading>
            </Flex>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
                <VStack spacing={4} align="stretch">
                    <form onSubmit={handleSubmit}>
                        <FormControl isRequired>
                            <FormLabel htmlFor="icalUrl">L'url du calendrier</FormLabel>

                            <Select placeholder="Sélectionnez votre groupe" value={icalUrl} onChange={(e) => setIcalUrl(e.target.value)}>
                                <option value="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/6YPaXQnv.shu">Groupe TP A</option>
                                <option value="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/pn89glW8.shu">Groupe TP B</option>
                                <option value="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/MYzNaJYZ.shu">Groupe TP C</option>
                                <option value="https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/bWKaVQYa.shu">Groupe TP D</option>
                            </Select>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel htmlFor="unwantedEvents">Les évènements non voulus dans le calendrier</FormLabel>
                            <Input id="unwantedEvents" type="text"  onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleAddUnwantedItem(event);
                                }
                            }} placeholder="Taper et appuyez sur entrer" />
                            <FormHelperText>Mettre seulement l'abréviation du cours (ex : WS) et pour la pause mettre "Pause")</FormHelperText>

                            <Box display="flex" flexWrap="wrap" justifyContent="space-around">
                                {unwantedEvents.map((item, index) => (
                                    <Badge
                                        key={index}
                                        p={2}
                                        bg="green.100"
                                        borderRadius="md"
                                        margin={2}
                                    >
                                        {item}
                                    </Badge>
                                ))}
                            </Box>
                        </FormControl>
                        <Button mt={4} colorScheme="teal" onClick={handleSubmit}>
                            Créer le lien pour le calendrier
                        </Button>
                    </form>
                    {generatedCalendarLink && (
                        <Box>
                            <Heading size="md" mb={2}>Lien du calendrier généré :</Heading>
                            <a href={generatedCalendarLink} target="_blank" rel="noopener noreferrer">{generatedCalendarLink}</a>
                            <CopyToClipboard mt={1} text={generatedCalendarLink}>
                                <Button onClick={() => toast({
                                    title: "Copié dans le presse-papiers",
                                    description: "L'URL a été copiée dans le presse-papiers.",
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true,
                                })}>
                                    <FaCopy /> Copier le lien
                                </Button>
                            </CopyToClipboard>
                        </Box>

                    )}
                </VStack>
            </Box>
        </Container>
        </div>
    );
}

export default CreateCalendarPage;
