import React from 'react';
import { Box, Flex, Link, useColorMode, IconButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';

const Navbarbis = () => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Box bg={colorMode === 'light' ? 'gray.100' : 'gray.900'} px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                    <Link as={RouterLink} to="/" px={2}>
                        Accueil
                    </Link>
                    <Link as={RouterLink} to="/create" px={2}>
                        Créer un calendrier
                    </Link>
                    <Link as={RouterLink} to="/view" px={2}>
                        Consulter les calendriers
                    </Link>
                    <Link as={RouterLink} to="/about" px={2}>
                        À propos
                    </Link>
                </Box>
                <Box>
                    <IconButton
                        icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
                        onClick={toggleColorMode}
                        aria-label="Toggle Color Mode"
                        mr={2}
                    />
                    {/* Ajoutez d'autres boutons ou liens ici si nécessaire */}
                </Box>
            </Flex>
        </Box>
    );
};

export default Navbarbis;
