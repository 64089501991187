import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    VStack,
    useToast,
    useColorMode,
    IconButton,
    Flex,
    Heading,
    Container,
    Text,
    Link,
    FormControl,
    FormLabel,
    Textarea, Divider, Input,
} from '@chakra-ui/react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

function AboutPage() {
    const { colorMode, toggleColorMode } = useColorMode();
    const toast = useToast();
    const [descriptionB, setDescriptionB] = useState('');
    const [email, setEmail] = useState('');
    const [descriptionI, setDescriptionI] = useState('');
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname , title: "À propos" });
    }, [location]);
    const handleBugReport = async () => {
        ReactGA.event({
            category: 'Interaction utilisateur',
            action: 'Création d\'un bug report'
        });
        const response = await fetch('https://calendrio.fr/reportBug', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, descriptionB }),
        });

        if (response.ok) {
            toast({
                title: "Bug reporté",
                description: "Merci pour votre retour, nous allons examiner ce bug.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setEmail('');
            setDescriptionB('');
        } else {
            console.error('Erreur lors du signalement du bug');
        }
    };

    const handleImproveReport = async () => {
        ReactGA.event({
            category: 'Interaction utilisateur',
            action: 'Création d\'un improve report'
        });
        const response = await fetch('https://calendrio.fr/reportImprove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, descriptionI }),
        });

        if (response.ok) {
            toast({
                title: "Amélioration proposée",
                description: "Merci pour ton retour, nous allons examiner ta proposition.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setEmail('');
            setDescriptionI('');
        } else {
            console.error('Erreur lors de la proposition d\'amélioration');
        }
    };


    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Calendrio - À propos</title>
                <link rel="canonical" href="https://calendrio.fr/about" />
            </Helmet>
        <Container maxW="container.md" p={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading as="h2" size="xl">À propos</Heading>
            </Flex>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
                <VStack spacing={4} align="stretch">
                    <Heading fontSize="xl">Elouan BARBIER</Heading>
                    <Text mt={4}>Je suis Elouan BARBIER, développeur de cette application. Si vous rencontrez des bugs, n'hésitez pas à les signaler ci-dessous.</Text>
                    <FormControl id="bug-report">
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ton email"
                        />
                        <FormLabel mt={4}>Signaler un bug</FormLabel>
                        <Textarea
                            value={descriptionB}
                            onChange={(e) => setDescriptionB(e.target.value)}
                            placeholder="Décrivez le bug rencontré"
                        />
                        <Button mt={4} colorScheme="teal" onClick={handleBugReport}>Signaler</Button>
                    </FormControl>
                    <Divider></Divider>
                    <FormControl id="improve-report">
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ton email"
                        />
                        <FormLabel mt={4}>Proposer une amélioration</FormLabel>
                        <Textarea
                            value={descriptionI}
                            onChange={(e) => setDescriptionI(e.target.value)}
                            placeholder="Proposer une amélioration"
                        />
                        <Button mt={4} colorScheme="teal" onClick={handleImproveReport}>Proposer</Button>
                    </FormControl>
                    <Heading fontSize="xl" mt={6}>Aide</Heading>
                    <Text mt={4}>Voici quelques articles qui pourraient vous aider :</Text>
                    {/* Remplacez 'ton_URL_DE_VIDEO' par l'URL de ton vidéo d'aide */}
                    <Link href="/" isExternal>Comment utiliser l'application</Link>
                    {/* Ajoutez autant de liens vers des articles d'aide que vous le souhaitez ici */}
                </VStack>
            </Box>
        </Container>
        </div>
    );
}

export default AboutPage;
