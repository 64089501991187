import './App.css';
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateCalendarPage from "./pages/CreateCalendarPage";
import ViewCalendarsPage from "./pages/ViewCalendarsPage";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import Navbarbis from "./components/Navbarbis";
import ReactGA from "react-ga4";

ReactGA.initialize("G-G1LMHRNXXN");
function App() {
  return (
      <ChakraProvider>
        <Router>
          <Navbarbis />
          <Routes>
            <Route path="/create" element={<CreateCalendarPage/>} />
            <Route path="/view" element={<ViewCalendarsPage/>} />
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/" element={<HomePage/>} />
          </Routes>
        </Router>
      </ChakraProvider>
  );
}

export default App;
