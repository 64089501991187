import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    useToast,
    useColorMode,
    IconButton,
    Flex,
    Heading,
    Container,
    List,
    ListItem,
    Text,
    Button
} from '@chakra-ui/react';
import { FaSun, FaMoon, FaCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";

function getGroupName(icalUrl) {
    if (icalUrl === "https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/6YPaXQnv.shu") {
        return "Groupe TP A";
    } else if (icalUrl === "https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/pn89glW8.shu") {
        return "Groupe TP B";
    } else if (icalUrl === "https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/MYzNaJYZ.shu") {
        return "Groupe TP C";
    } else if (icalUrl === "https://planning.univ-rennes1.fr/jsp/custom/modules/plannings/bWKaVQYa.shu") {
        return "Groupe TP D";
    } else {
        return "Groupe non reconnu";
    }
}

function ViewCalendarsPage() {
    const [groupedCalendars, setGroupedCalendars] = useState({});
    const toast = useToast(); // Pour afficher des messages temporaires
    const { colorMode, toggleColorMode } = useColorMode();
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname , title: "Tous les calendriers" });
    }, [location]);

    useEffect(() => {
        const fetchCalendars = async () => {
            try {
                const response = await fetch('https://calendrio.fr/calendars');
                if (!response.ok) throw new Error('Erreur lors de la récupération des calendriers');
                const result = await response.json();

                // Groupement des calendriers par nom de groupe
                const grouped = {};
                result.forEach(calendar => {
                    const groupName = getGroupName(calendar.ical_url);
                    if (!grouped[groupName]) {
                        grouped[groupName] = [];
                    }
                    grouped[groupName].push(calendar);
                });

                setGroupedCalendars(grouped);
            } catch (error) {
                toast({
                    title: 'Erreur',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchCalendars(); // Appel de la fonction de récupération des calendriers
    }, []);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Calendrio - Tous les calendriers</title>
                <link rel="canonical" href="https://calendrio.fr/view" />
            </Helmet>
        <Container maxW="container.md" p={4} ml={0}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading as="h2" size="xl">Calendriers disponibles</Heading>
            </Flex>
            <Flex ml={0}>
                {Object.entries(groupedCalendars)
                    .sort(([groupNameA], [groupNameB]) => {
                        // You can customize this sorting logic according to your specific needs.
                        // This example assumes that the group name starts with "TP" followed by a letter.
                        const orderMap = { A: 1, B: 2, C: 3, D: 4 };
                        const orderA = orderMap[groupNameA.charAt(10)];
                        const orderB = orderMap[groupNameB.charAt(10)];
                        return orderA - orderB;
                    })
                    .map(([groupName, calendars]) => (
                    <Box key={groupName} flex={1} mr={4}>
                        <Heading size="lg" mb={4}>{groupName}</Heading>
                        <VStack spacing={4} align="stretch">
                            {calendars.map((calendar, index) => (
                                <Box key={index} p={5} shadow="md" borderWidth="1px">
                                    <Heading fontSize="xl">{calendar.name}</Heading>
                                    <Text mt={4}>URL : https://calendrio.fr/calendar/{calendar.calendar_id}</Text>
                                    <CopyToClipboard mt={1} text={`https://calendrio.fr/calendar/${calendar.calendar_id}`}>
                                        <Button onClick={() => toast({
                                            title: "Copié dans le presse-papiers",
                                            description: "L'URL a été copiée dans le presse-papiers.",
                                            status: "success",
                                            duration: 3000,
                                            isClosable: true,
                                        })}>
                                            <FaCopy /> Copier le lien
                                        </Button>
                                    </CopyToClipboard>
                                    <Text mt={4}>Matières exclue du calendrier</Text>
                                    <List spacing={3} mt={2}>
                                        {calendar.unwanted_events.map((item, index) => (
                                            <ListItem key={index} p={2} bg={colorMode === 'light' ? 'blue.100' : 'blue.600'} borderRadius="md">{item}</ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ))}
                        </VStack>
                    </Box>
                ))}
            </Flex>
        </Container>
        </div>
    );
}

export default ViewCalendarsPage;
